import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Manually disable scroll restoration to avoid unexpected scrolling behavior
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Scroll the viewport to the top on route change
    window.scrollTo(0, 0);

    return () => {
      // Optionally, reset scrollRestoration to default if needed
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto';
      }
    };
  }, [pathname]); // Trigger whenever the pathname changes

  return children;
};

export default ScrollToTop;
