import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../apiConfig";

const EnquiryForm = ({ isOpen, onClose, onSubmit, university_id }) => {
  const modalRef = useRef();
  const [programOptions, setProgramOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    contact_no: "",
    program: "",
    university_id: university_id || "",
  });

  const [errors, setErrors] = useState({});
  const userData = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/program_master`);
        setProgramOptions(response.data);
      } catch (error) {
        toast.error("Failed to load programs", {
          style: { border: "2px solid red" },
        });
      }
    };

    fetchPrograms();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    if (userData?.registration_id) {
      setFormData((prevData) => ({
        ...prevData,
        name: userData.name || "",
        contact_no: userData.mobilenumber || "",
      }));
    }
  }, [userData]);

  const resetForm = () => {
    setFormData({
      name: userData?.registration_id ? userData.name : "",
      contact_no: userData?.registration_id ? userData.mobilenumber : "",
      program_master_id: "",
      university_id: university_id || "",
    });
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required.";
    }
    if (!formData.contact_no) {
      newErrors.contact_no = "Contact number is required.";
    } else if (!/^\d{10}$/.test(formData.contact_no)) {
      newErrors.contact_no = "Contact number must be 10 digits.";
    }
    if (!formData.program_master_id) {
      newErrors.program = "Program selection is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const payload = {
      name: formData.name,
      mobilenumber: formData.contact_no,
      university_id: formData.university_id,
      program_master_id: formData.program_master_id || undefined,
      created_by: userData.registration_id,
    };

    try {
      const response = await axios.post(`${Base_URL}/create/application_request_university`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Application submitted successfully!");
      resetForm();
      onClose();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Submission failed! Please try again.");
      }
    }
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='absolute inset-y-[150px] flex items-center justify-center w-full px-4'>
        <div
          ref={modalRef}
          className='bg-white rounded-lg p-6 w-full max-w-md mx-auto relative'
        >
          <div className='flex justify-between items-center mb-6'>
            <h2 className='text-xl font-semibold text-gray-800'>
              Enquiry Form
            </h2>
          </div>

          <form onSubmit={handleSubmit} className='space-y-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Name
              </label>
              <input
                type='text'
                name='name'
                value={formData.name}
                onChange={handleInputChange}
                className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm'
                required
              />
              {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}
            </div>

            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Contact Number
              </label>
              <input
                type='tel'
                name='contact_no'
                value={formData.contact_no}
                onChange={handleInputChange}
                className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm'
                required
              />
              {errors.contact_no && <p className="text-red-500 text-xs">{errors.contact_no}</p>}
            </div>

            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Program
              </label>
              <select
                name='program_master_id'
                value={formData.program_master_id}
                onChange={handleInputChange}
                className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm'
                required
              >
                <option value=''>Select Program</option>
                {programOptions.map((program) => (
                  <option key={program.id} value={program.id}>
                    {program.program_master_name}
                  </option>
                ))}
              </select>
              {errors.program_master_id && <p className="text-red-500 text-xs">{errors.program_master_id}</p>}
            </div>

            <input
              type="hidden"
              name="university_id"
              value={formData.university_id}
            />

            <button
              type='submit'
              className='w-full bg-cmsgreen text-white py-2 px-4 rounded-md hover:bg-green-700 transition-colors duration-200'
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
